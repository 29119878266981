(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbAccount.wbCompany.factory:Company
   *
   * @description
   *
   */
  angular
    .module('wbAccount.wbCompany')
    .factory('Company', Company);

  function Company(wbResource, Order, Card, DataReconciliation) {
    var url = 'companies/'
      , xlsxContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      , CompanyBase = wbResource('company', url + ':companyId', {companyId: '@id'}, {
        query: {
          method: 'GET',
          cache: false,
          isArray: true
        },
        get: {
          method: 'GET',
          cache: false,
          isArray: false
        },
        callback: {
          method: 'POST',
          url: 'callback',
          cache: false
        },
        getCsnFile: {
          method: 'GET',
          url: url + ':companyId/csnFiles/:id',
          params: {
            id: '@id',
            companyId: '@companyId'
          },
          headers: {
            accept: xlsxContentType
          },
          responseType: 'arraybuffer',
          cache: false,
          transformResponse: function (data) {
            return {
              response: new Blob([data], {type: xlsxContentType})
            };
          }
        },
        getCsnFiles: {
          url: url + ':companyId/csnFiles',
          params: {
            companyId: '@companyId'
          },
          method: 'GET',
          cache: false,
          isArray: true
        },
        getGeneratedCsnFile: {
          method: 'GET',
          url: url + ':companyId/generatedCsnFiles/:id',
          params: {
            id: '@id',
            companyId: '@companyId'
          },
          headers: {
            accept: xlsxContentType
          },
          responseType: 'arraybuffer',
          cache: false,
          transformResponse: function (data) {
            return {
              response: new Blob([data], {type: xlsxContentType})
            };
          }
        },
        getGeneratedCsnFiles: {
          url: url + ':companyId/generatedCsnFiles',
          params: {
            companyId: '@companyId'
          },
          method: 'GET',
          cache: false,
          isArray: true
        },
        generateNewCsnFiles: {
          url: url + ':companyId/generatedCsnFiles',
          params: {
            companyId: '@companyId'
          },
          method: 'POST',
          cache: false,
          isArray: true
        },
        generateAndGetCsnFile: {
          method: 'GET',
          url: url + ':companyId/completeCsnFile',
          params: {
            companyId: '@companyId'
          },
          headers: {
            accept: xlsxContentType
          },
          responseType: 'arraybuffer',
          cache: false,
          transformResponse: function (data) {
            return {
              response: new Blob([data], {type: xlsxContentType})
            };
          }
        },
      });

    CompanyBase.prototype.getOrder = function getOrder() {
      return Order.getAsSub(url + this.id + '/');
    };

    CompanyBase.prototype.getDataReconciliation = function getDataReconciliation() {
      return DataReconciliation.getAsSub(url + this.id + '/');
    };

    CompanyBase.prototype.getCard = function getCard() {
      return Card.getAsSub(url + this.id + '/');
    };

    return CompanyBase;
  }
}());
